import { Box, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import DocLink from '../../../../../common/components/doc_link/utils/DocLink';
import getConsolidatedDocById from '../../../../../common/components/doc_link/utils/getConsolidatedDocById';

const Introduction = () => (
  <div>
    <SpaceBetween size="l" direction="horizontal">
      <Box fontSize="heading-xl" fontWeight="normal" variant="h2">
        Your survey(s) - secondary assignee
      </Box>
      <Box padding={{ top: 's' }}>
        <DocLink
          pdf={getConsolidatedDocById(process.env.REACT_APP_FAQ_FILE_ID)}
          text="Why am I a secondary assignee?"
          variant="info"
        />
      </Box>
    </SpaceBetween>
    <Box variant="p" color="text-body-secondary">
      You are the secondary assignee of the following survey. You can edit the survey at any time
      before the survey is completed by its primary assignee.
    </Box>
  </div>
);

export default Introduction;
