import FAQConsolidated from '../pdfs/Consolidated_FAQ_US.pdf';
import FAQDocUK from '../pdfs/RadicalFAQ_UK.pdf';
import { FAQ_UK, FAQ_US } from '../../../constants/documentIds';

const getConsolidatedDocById = docId => {
  switch (docId) {
    case FAQ_UK:
      return FAQDocUK;
    case FAQ_US:
      return FAQConsolidated;
    default:
      return null;
  }
};
export default getConsolidatedDocById;
