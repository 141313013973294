import React, { useEffect, useState } from 'react';
import { Alert, Box, Button, Modal, SpaceBetween } from '@amzn/awsui-components-react';
import { filter, forEach, get, isEmpty, map } from 'lodash';
import PropTypes from 'prop-types';

import { round, sum } from 'lodash/math';
import { values } from 'lodash/object';
import * as XLSX from 'xlsx';
import { connect, useDispatch } from 'react-redux';
import AllocationGrid from '../../AllocationGrid/AllocationGrid';
import { errorMessages, validationInitialState } from '../../../constants/steps-config';
import { getEntityData, getSurveyEntity } from '../../../../../common/constants/surveyType';
import { isReadOnly } from '../../../utils/survey_page_utils';
import FillMatrixWithExcel from '../../AllocationGrid/FillMatrixWithExcel';
import { setProjectAllocation as setProjectAllocationAction } from '../../../redux/actions/projectAllocationActions';
import { setCopyGridValues as setCopyGridValuesAction } from '../../../redux/actions/copyGridValuesActions';
import './ProjectAllocation.css';
// eslint-disable-next-line import/no-cycle
import stepsFn from '../../../constants/SurveySteps';

const ProjectAllocation = ({
  state,
  setState,
  pageContents,
  validationState,
  setValidationState,
  onNavigate,
  surveyType,
  surveyDetails,
  projectAllocation,
  copyGridValues,
  templateId,
  derivedClientId,
}) => {
  const dispatch = useDispatch();
  const entityData = getEntityData(pageContents, surveyType);
  const projectRemoved = get(validationState, 'projectAllocationState.projectRemoved', false);
  const selectedProject = get(state, 'Project.data.selectedProjects', {});
  const steps = stepsFn(templateId, surveyType, derivedClientId);

  const setCopyGridValues = (value, column) => {
    dispatch(
      setCopyGridValuesAction({
        ...copyGridValues,
        projectCopiedValues: value,
        projectCopiedColumn: column,
      }),
    );
  };

  const validateProjectAllocation = project => {
    // First check if project exists and has the right structure
    if (!project || typeof project !== 'object') {
      return false;
    }

    const entityList = entityData;
    if (isEmpty(project) || Object.keys(project).length !== entityList.length) return false;

    let validation = true;
    Object.keys(project).forEach(employee => {
      if (
        !project[employee] ||
        typeof project[employee] !== 'object' ||
        typeof project[employee].total === 'undefined' ||
        Number(project[employee].total) !== 100
      ) {
        validation = false;
      }
    });
    return validation;
  };

  const setProjectAllocation = val => {
    if (!val || !val.data) {
      return;
    }

    const newData = {
      ...val,
      data: {},
    };

    entityData.forEach(entity => {
      if (entity && entity.id) {
        newData.data[entity.id] = val.data[entity.id] || {};
      }
    });

    dispatch(
      setProjectAllocationAction({
        ...newData,
        error: !validateProjectAllocation(newData?.data),
        errorMessage: null,
      }),
    );
  };

  useEffect(() => {
    const Projects = map(get(state, 'Project.data.selectedProjects', []), ob => ob.id);
    const oldState = projectAllocation?.data || {};

    const newState = {};
    forEach(entityData, entity => {
      if (entity && entity.id) {
        const x = {};
        forEach(Projects, key => {
          if (oldState[entity.id]?.[key] && oldState[entity.id][key] !== '0') {
            x[key] = oldState[entity.id][key];
          }
        });

        const totalSum = sum(
          map(values(x), val => {
            const parsed = parseFloat(val);
            // eslint-disable-next-line no-restricted-globals
            return !isNaN(parsed) ? round(parsed, 1) : 0;
          }),
        );

        x.total = totalSum.toFixed(1).toString();
        newState[entity.id] = x;
      }
    });

    setProjectAllocation({
      ...projectAllocation,
      data: newState,
      error: !validateProjectAllocation(newState),
      errorMessage: Projects.length === 0 ? get(errorMessages, [surveyType, 'Project']) : null,
    });
  }, []);

  const projects = get(state, 'Project.data.selectedProjects', []);

  const uploadedData = importedData => {
    if (!importedData) {
      return;
    }

    if (importedData.status === 'error') {
      setProjectAllocation({
        ...projectAllocation,
        errorMessage: importedData.errorMessage,
      });
    } else {
      setProjectAllocation({
        ...projectAllocation,
        data: importedData,
        error: !validateProjectAllocation(importedData),
        errorMessage: null,
      });
    }
  };

  const updateActiveIndex = () => {
    const step = steps.findIndex(eachStep => eachStep.stateKey === 'ActivityAllocation');
    setState({
      key: 'activeStep',
      value: step,
    });
  };

  const closeNoAllocationModal = () => {
    setValidationState(validationInitialState);
  };

  useEffect(() => {
    if (projectRemoved) {
      setValidationState(validationInitialState);
      const detail = {
        requestedStepIndex: state.activeStep + 1,
      };
      onNavigate({ detail });
    }
  }, [selectedProject, projectRemoved]);

  const removeProjectFromSelectedList = () => {
    const currentSelectedProject = get(state, 'Project.data.selectedProjects', {});
    const removeProjectId = validationState.projectAllocationState.projectId;
    const currentSelection = filter(currentSelectedProject, project => {
      return project.id !== removeProjectId;
    });
    setState({
      key: 'Project',
      value: {
        ...state.Project,
        data: {
          ...get(state, 'Project.data', {}),
          selectedProjects: currentSelection,
        },
      },
    });
    setValidationState(prevState => ({
      ...prevState,
      projectAllocationState: {
        ...prevState.projectAllocationState,
        projectRemoved: true,
      },
    }));
  };

  const { error, errorMessage } = projectAllocation || {};

  const exportGridToExcel = (
    colsEntity,
    rowsEntity,
    gridValues,
    fileName = 'project-allocation-grid.xlsx',
  ) => {
    const worksheetData = [];

    const headerRow = ['Projects', ...colsEntity.map(col => col.name)];
    worksheetData.push(headerRow);

    rowsEntity.forEach(row => {
      const rowData = [row.name];
      colsEntity.forEach(col => {
        const cellValue = gridValues?.[col.id]?.[row.id] || '';
        rowData.push(cellValue);
      });
      worksheetData.push(rowData);
    });

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'projects');
    XLSX.writeFile(workbook, fileName);
  };

  const handleExportClick = (colsEntity, rowsEntity, gridValues) => {
    exportGridToExcel(colsEntity, rowsEntity, gridValues);
  };

  const getEmployeeIdByName = (employeeName, colsEntity) => {
    const employee = colsEntity.find(col => col?.name === employeeName);
    return employee?.id || null;
  };

  const getProjectIdByName = (projectName, rowsEntity) => {
    const project = rowsEntity.find(row => row?.name === projectName);
    return project?.id || null;
  };

  const parseExcel = (file, colsEntity, rowsEntity) => {
    const reader = new FileReader();

    reader.onload = function parseExcelOnLoad(e) {
      try {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        if (!Array.isArray(jsonData) || jsonData.length < 2) {
          throw new Error('Invalid Excel data structure');
        }

        const [headerRow, ...dataRows] = jsonData;
        const gridData = {};

        dataRows.forEach(row => {
          if (!row || !row[0]) return;

          const projectName = row[0];
          const projectId = getProjectIdByName(projectName, rowsEntity);

          if (projectId) {
            headerRow.slice(1).forEach((employeeName, index) => {
              const employeeId = getEmployeeIdByName(employeeName, colsEntity);
              if (employeeId) {
                const cellValue = row[index + 1] !== undefined ? String(row[index + 1]) : '0';

                if (cellValue !== '' && parseFloat(cellValue) !== 0) {
                  if (!gridData[employeeId]) {
                    gridData[employeeId] = {};
                  }
                  gridData[employeeId][projectId] = cellValue;
                }
              }
            });
          }
        });

        // Calculate totals
        Object.keys(gridData).forEach(employeeId => {
          if (!gridData[employeeId]) {
            gridData[employeeId] = {};
          }

          const total = Object.entries(gridData[employeeId])
            .filter(([key]) => key !== 'total')
            // eslint-disable-next-line no-shadow,no-unused-vars
            .reduce((sum, [_, value]) => {
              const numValue = parseFloat(String(value) || '0');
              // eslint-disable-next-line no-restricted-globals
              return !isNaN(numValue) ? sum + numValue : sum;
            }, 0);

          gridData[employeeId].total = total.toFixed(1);
        });

        setProjectAllocation({
          ...projectAllocation,
          data: gridData,
          error: !validateProjectAllocation(gridData),
          errorMessage: null,
        });
        // eslint-disable-next-line no-shadow
      } catch (error) {
        setProjectAllocation({
          ...projectAllocation,
          errorMessage: 'Error parsing Excel file. Please check the file format.',
        });
      }
    };

    reader.readAsBinaryString(file);
  };

  const handleFileSelected = file => {
    if (file) {
      parseExcel(file, entityData, projects);
    }
  };

  const [excelUploadModal, setExcelUploadModal] = useState(false);

  return (
    <SpaceBetween size="xs">
      {error && (
        <Alert visible="true" dismissAriaLabel="Close alert" type="error">
          You must allocate a total of 100% to each {getSurveyEntity(surveyType)}
        </Alert>
      )}

      {errorMessage && (
        <Alert visible="true" dismissAriaLabel="Close alert" type="error">
          {errorMessage}
        </Alert>
      )}

      <FillMatrixWithExcel
        isVisible={excelUploadModal}
        setModalVisible={setExcelUploadModal}
        onDownloadTemplate={() => handleExportClick(entityData, projects, projectAllocation?.data)}
        onFillMatrix={handleFileSelected}
      />

      <AllocationGrid
        titleEnitity="projects"
        title={
          <div className="table-header-project-alloc">
            <h2>Employee project matrix</h2>
            <Box float="right">
              <Button
                onClick={() => setExcelUploadModal(true)}
                disabled={isReadOnly(surveyDetails)}
              >
                Fill matrix with Excel
              </Button>
            </Box>
          </div>
        }
        colsEntity={entityData}
        rowsEntity={projects}
        uploadedData={uploadedData}
        selectEntity={updateActiveIndex}
        isDownloadUploadButtonDisabled={projects.length === 0}
        allocationType="Project"
        surveyType={surveyType}
        surveyDetails={surveyDetails}
        gridValues={projectAllocation?.data}
        setGridValues={val => {
          if (!val) return;

          const newGridData = {
            ...projectAllocation?.data,
            ...val,
          };
          const newAllocationData = {
            ...projectAllocation,
            data: newGridData,
            error: !validateProjectAllocation(newGridData),
            errorMessage: null,
          };
          setProjectAllocation(newAllocationData);
        }}
        copiedValues={copyGridValues.projectCopiedValues}
        copiedColumn={copyGridValues.projectCopiedColumn}
        setCopiedValues={setCopyGridValues}
      />
      <Modal
        onDismiss={closeNoAllocationModal}
        visible={validationState.projectAllocationState.modalVisible}
        closeAriaLabel="Close modal"
        size="medium"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="normal" onClick={closeNoAllocationModal}>
                Edit allocation
              </Button>
              <Button variant="primary" onClick={removeProjectFromSelectedList}>
                Proceed
              </Button>
            </SpaceBetween>
          </Box>
        }
        header="Project has no time allocation"
      >
        <div>
          You didn&apos;t allocate any of the employee&apos;s time to project{' '}
          <b>{validationState.projectAllocationState.projectName}</b>. If you choose to proceed,
          this project will be removed from your project list.
        </div>
      </Modal>
    </SpaceBetween>
  );
};

ProjectAllocation.propTypes = {
  state: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired,
  pageContents: PropTypes.object.isRequired,
  surveyType: PropTypes.string.isRequired,
  validationState: PropTypes.object.isRequired,
  setValidationState: PropTypes.func.isRequired,
  onNavigate: PropTypes.func.isRequired,
  surveyDetails: PropTypes.object.isRequired,
  projectAllocation: PropTypes.object.isRequired,
  copyGridValues: PropTypes.object.isRequired,
  templateId: PropTypes.string.isRequired,
  derivedClientId: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  projectAllocation: get(state, 'projectAllocation', {}),
  copyGridValues: get(state, 'copyGridValues', {}),
  derivedClientId: get(state, 'derivedClientId.derivedClientId', undefined),
});

export default connect(mapStateToProps)(ProjectAllocation);
