import FAQDocUK from '../pdfs/RadicalFAQ_UK.pdf';
import FAQDocUS from '../pdfs/RadicalFAQ_US.pdf';
import FAQDocDualUS from '../pdfs/Dual Allocation_US Survey_FAQs.pdf';

import { FAQ_UK, FAQ_US } from '../../../constants/documentIds';
import { SURVEY_TYPE } from '../../../constants/surveyType';

const getDocById = (docId, surveyType = 'default') => {
  if (surveyType === SURVEY_TYPE.LABOR_DUAL && docId === FAQ_US) {
    return FAQDocDualUS;
  }
  if (docId === FAQ_US) {
    return FAQDocUS;
  }
  if (docId === FAQ_UK) {
    return FAQDocUK;
  }
  return null;
};

export default getDocById;
