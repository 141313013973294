import React, { useMemo, useState } from 'react';
import { Alert, Button, Header, SpaceBetween } from '@amzn/awsui-components-react';
import { get, map } from 'lodash';
import PropTypes from 'prop-types';

import { connect, useDispatch } from 'react-redux';
import * as XLSX from 'xlsx';
import {
  getEntityData,
  getSurveyEntity,
  SURVEY_TYPE,
} from '../../../../../common/constants/surveyType';
import SectionedAllocationGrid from '../../AllocationGrid/SectionedAllocationGrid';
import { setProjectAllocation as setProjectAllocationAction } from '../../../redux/actions/projectAllocationActions';
import { isReadOnly } from '../../../utils/survey_page_utils';
import FillMatrixWithExcel from '../../AllocationGrid/FillMatrixWithExcel';
import './AdjustProjectAllocation.css';

const TableHeading = ({ heading, setExcelUploadModal, surveyDetails }) => (
  <SpaceBetween size="xs">
    <Header
      headingTagOverride="h2"
      actions={
        <SpaceBetween size="xxl" direction="horizontal">
          <Button onClick={() => setExcelUploadModal(true)} disabled={isReadOnly(surveyDetails)}>
            Fill matrix with Excel
          </Button>
        </SpaceBetween>
      }
    >
      <div className="table-header">{heading}</div>
    </Header>
    <div className="table-title-subheading">
      Review the amount of time each employee spent on this Business Component.
    </div>
  </SpaceBetween>
);

TableHeading.propTypes = {
  heading: PropTypes.string.isRequired,
  setExcelUploadModal: PropTypes.func.isRequired,
  surveyDetails: PropTypes.object.isRequired,
};

const AdjustProjectAllocation = ({
  state,
  pageContents,
  surveyType,
  projectAllocation,
  surveyDetails,
  derivedClientId,
}) => {
  const dispatch = useDispatch();
  const entityData = useMemo(() => getEntityData(pageContents, surveyType), [
    pageContents,
    surveyType,
  ]);
  const selectedProject = get(state, 'Project.data.selectedProjects', []);
  const [excelUploadModal, setExcelUploadModal] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(undefined);
  // eslint-disable-next-line no-unused-vars
  const [errorMessage, setErrorMessage] = useState(undefined);
  const gridTitle =
    selectedProject.length === 1
      ? `Business Component: ${selectedProject[0].name}`
      : 'Employee Project Matrix';

  const getColumnHeaders = projects => {
    const firstColumn = {
      id: 'employees',
      label: 'Employees',
      header: 'Employees',
      cell: rowEntity => rowEntity.name,
      sticky: 'left',
      minWidth: 174,
      maxWidth: 174,
    };
    return [
      firstColumn,
      ...map(projects, project => {
        return {
          id: project.id,
          label:
            projects.length === 1 ? 'Time allocation for the business component' : project.name,
          header:
            projects.length === 1 ? 'Time allocation for the business component' : project.name,
          cell: rowEntity => (rowEntity ? rowEntity[project.id] ?? '' : ''),
          sticky: 'left',
          minWidth: 144,
        };
      }),
    ];
  };

  const columns = useMemo(() => getColumnHeaders(selectedProject), [selectedProject]);

  const setProjectAllocation = val => {
    dispatch(setProjectAllocationAction(val));
  };

  const validateProjectAllocation = newGridData => {
    if (!newGridData) {
      return !!newGridData;
    }
    return false;
  };

  const getProjectIdByName = (projectName, rowsEntity) => {
    const project = rowsEntity.find(row => row.name === projectName);
    return project ? project.id : null;
  };

  const projects = get(state, 'Project.data.selectedProjects', []);
  const exportGridToExcelForUK = (
    colsEntity,
    rowsEntity,
    gridValues,
    fileName = 'project-allocation-grid.xlsx',
  ) => {
    const worksheetData = [];

    const headerRow = ['Projects', ...colsEntity.map(col => col.name)];
    worksheetData.push(headerRow);

    rowsEntity.forEach(row => {
      const rowData = [`${row.name} | ${row.employeeAlias} | ${row.id}`];
      colsEntity.forEach(col => {
        const cellValue = gridValues[row.id] ? gridValues[row.id][col.id] || '' : '';
        rowData.push(cellValue);
      });
      worksheetData.push(rowData);
    });

    // Convert the worksheet data to a worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'projects');

    XLSX.writeFile(workbook, fileName);
  };

  const exportGridToExcel = (
    colsEntity,
    rowsEntity,
    gridValues,
    fileName = 'project-allocation-grid.xlsx',
  ) => {
    const worksheetData = [];
    const headerRow = [
      'Id',
      'Alias',
      'Name',
      'Job Title',
      'SME',
      'Reports to',
      ...colsEntity.map(col => col.name),
    ];
    worksheetData.push(headerRow);

    rowsEntity.forEach(row => {
      const rowData = [
        `${row.id}`,
        `${row.employeeAlias}`,
        `${row.name}`,
        `${row.jobTitle}`,
        `${row.smeName}`,
        `${row.supervisorName}`,
      ];
      colsEntity.forEach(col => {
        const cellValue = gridValues[row.id] ? gridValues[row.id][col.id] || '' : '';
        rowData.push(cellValue);
      });
      worksheetData.push(rowData);
    });

    // Convert the worksheet data to a worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'projects');

    XLSX.writeFile(workbook, fileName);
  };

  const handleExportClick = (colsEntity, rowsEntity, gridValues) => {
    if (derivedClientId === 'Radical' && surveyType === SURVEY_TYPE.LABOR_DUAL) {
      exportGridToExcel(colsEntity, rowsEntity, gridValues);
    } else {
      exportGridToExcelForUK(colsEntity, rowsEntity, gridValues);
    }
  };

  const parseExcel = (file, rowsEntity, colsEntity) => {
    const reader = new FileReader();

    reader.onload = function parseExcelOnLoad(e) {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const [headerRow, ...dataRows] = jsonData;

      const gridData = {};

      headerRow.slice(1).forEach((projectName, index) => {
        const projectId = getProjectIdByName(projectName, colsEntity);
        if (projectId) {
          dataRows.forEach(row => {
            const employeeIdentifierSplit = row[0].split(' | ');
            const employeeId = employeeIdentifierSplit[employeeIdentifierSplit.length - 1];
            if (employeeId) {
              const cellValue = row[index + 1] !== undefined ? String(row[index + 1]) : '0';

              if (cellValue !== '' && parseFloat(cellValue) !== 0) {
                if (!gridData[employeeId]) {
                  gridData[employeeId] = {};
                }

                gridData[employeeId][projectId] = cellValue;
              }
            }
          });
        }
      });

      Object.keys(gridData).forEach(employeeId => {
        gridData[employeeId].total = Object.values(gridData[employeeId])
          .reduce((totalSum, val) => totalSum + parseFloat(String(val) || '0'), 0)
          .toFixed(1);
      });

      setProjectAllocation({
        ...projectAllocation,
        data: gridData,
        error: !validateProjectAllocation(gridData),
        errorMessage: null,
      });
    };

    reader.readAsBinaryString(file);
  };

  const handleFileSelected = file => {
    if (file) {
      parseExcel(file, entityData, projects);
    }
  };

  return (
    <SpaceBetween size="xs">
      {error && (
        <Alert visible="true" dismissAriaLabel="Close alert" type="error">
          TBD You must allocate a total of 100% to each {getSurveyEntity(surveyType)}
        </Alert>
      )}

      {errorMessage && (
        <Alert visible="true" dismissAriaLabel="Close alert" type="error">
          TBD {errorMessage}
        </Alert>
      )}

      <FillMatrixWithExcel
        isVisible={excelUploadModal}
        setModalVisible={setExcelUploadModal}
        onDownloadTemplate={() => handleExportClick(projects, entityData, projectAllocation?.data)}
        onFillMatrix={handleFileSelected}
      />

      <TableHeading
        heading={gridTitle}
        setExcelUploadModal={setExcelUploadModal}
        surveyDetails={surveyDetails}
      />

      <SectionedAllocationGrid
        columns={columns}
        surveyType={surveyType}
        surveyDetails={surveyDetails}
        getSectionHeader={employee => employee.jobTitle}
        entityData={entityData}
        gridValues={projectAllocation?.data}
        setGridValues={val => {
          const newGridData = {
            ...projectAllocation?.data,
            ...val,
          };
          const newAllocationData = {
            ...projectAllocation,
            data: newGridData,
            error: !validateProjectAllocation(newGridData),
            errorMessage: null,
          };
          setProjectAllocation(newAllocationData);
        }}
      />
    </SpaceBetween>
  );
};
AdjustProjectAllocation.propTypes = {
  state: PropTypes.object.isRequired,
  pageContents: PropTypes.object.isRequired,
  surveyType: PropTypes.string.isRequired,
  surveyDetails: PropTypes.object.isRequired,
  projectAllocation: PropTypes.object.isRequired,
  derivedClientId: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  projectAllocation: get(state, 'projectAllocation', {}),
  copyGridValues: get(state, 'copyGridValues', {}),
  derivedClientId: get(state, 'derivedClientId.derivedClientId', undefined),
});

export default connect(mapStateToProps)(AdjustProjectAllocation);
